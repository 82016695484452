import {
    Loading, Message
} from 'element-ui';

let loadingInstance = null;

export const EZHud = {
    loading(options) {
        this.close()
        loadingInstance = Loading.service({
            fullscreen: true,
            background: '#ffffff32'
        })
    },
    close(loading = true, toast = true) {
        if (loading && loadingInstance) {
            // this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                loadingInstance.close();
                loadingInstance = null
            // });
        }
        if (toast) {
            Message.closeAll()
        }
    },
    toast(message, type = 'info', callback) {
        this.close()
        let duration = 2000
        Message({
            message: message,
            type: type,
            duration: duration
        })

        if (typeof callback == 'function') {
            setTimeout(() => {
                callback()
            }, duration);
        }
    },
    success(message, callback) {
        this.toast(message, 'success', callback)
    },
    error(message, callback) {
        this.toast(message, 'error', callback)
    },
    info(message, callback) {
        this.toast(message, 'info', callback)
    },
    warning(message, callback) {
        this.toast(message, 'warning', callback)
    }

}