const WEEK_MAP = {
    0: { zh: '周日', en: 'Sunday', short: 'Sun.'},
    1: { zh: '周一', en: 'Monday', short: 'Mon.'},
    2: { zh: '周二', en: 'Tuesday', short: 'Tues.'},
    3: { zh: '周三', en: 'Wednesday', short: 'Wed.'},
    4: { zh: '周四', en: 'Thursday', short: 'Thur.'},
    5: { zh: '周五', en: 'Friday', short: 'Fri.'},
    6: { zh: '周六', en: 'Saturday', short: 'Sat.'},
}
export const EZDate = {
    format: (date, fmt = 'yyyy-MM-dd hh:mm:ss') => {
        var o = {
            "M+": date.getMonth() + 1, //月份 
            "d+": date.getDate(), //日 
            "h+": date.getHours(), //小时 
            "m+": date.getMinutes(), //分 
            "s+": date.getSeconds(), //秒 
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
            "S": date.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    },
    formatWeek: (date = new Date()) => {
        let fmtDate = EZDate.format(date, 'yyyy/MM/dd')
        return fmtDate + ' ' + WEEK_MAP[date.getDay()].zh
    },
    from: (timestamp) => {
        return new Date(timestamp)
    },
    from: (dateStr, fmt = 'yyyy-MM-dd hh:mm:ss')=> {
        return new Date(dateStr)
    },
    getDateDiff(date, fmt = 'yyyy-MM-dd hh:mm:ss') {
        // 时间字符串转时间戳
        var timestamp = this.from(date, fmt).getTime();
        var minute = 1000 * 60;
        var hour = minute * 60;
        var day = hour * 24;
        var halfamonth = day * 15;
        var month = day * 30;
        var year = day * 365;
        var now = new Date().getTime();
        var diffValue = now - timestamp;
        var result;
        if (diffValue < 0) {
            return;
        }
        var yearC = diffValue / year;
        var monthC = diffValue / month;
        var weekC = diffValue / (7 * day);
        var dayC = diffValue / day;
        var hourC = diffValue / hour;
        var minC = diffValue / minute;
        // if (yearC >= 1) {
        //     result = "" + parseInt(yearC) + "年前";
        // } else if (monthC >= 1) {
        //     result = "" + parseInt(monthC) + "月前";
        // } else 
        if (weekC >= 1) {
            // result = "" + parseInt(weekC) + "周前";
            result = date
        } else if (dayC >= 1) {
            result = "" + parseInt(dayC) + "天前";
        } else if (hourC >= 1) {
            result = "" + parseInt(hourC) + "小时前";
        } else if (minC >= 1) {
            result = "" + parseInt(minC) + "分钟前";
        } else
            result = "刚刚";
        return result;
    }
}