import {
  tableData
} from "./table-mock-data"

import Cookies from 'js-cookie'
// 加密解密
let Base64 = require('js-base64').Base64


const SchoolType = {
  '0': '普通学校',
  '1': '弦乐学校',
}

const SchoolStatus = {
  '1': '正常',
  '0': '停用',
  '-1': '禁用',
  // '-9': '注销',
}

const RoleCategory = {
  'GLOBAL': '通用',
  'CHORD': '弦乐专属',
}

const RoleMap = {
  'GUITAR': '吉他',
  'BASS': '贝斯',
  'DRUM': '鼓',
  'PIANO': '钢琴',
  'SINGER': '唱',
  'UKULELE': '尤克里里',
  'VIOLIN': '小提琴',
  'CELLO': '大提琴',
  'NONE': '没有名字',
}

const RoleLevel = {
  'EASY': '简单',
  'NORMAL': '正常',
  'HARD': '困难'
}

export const sys_def = {
  SchoolType: SchoolType,
  SchoolStatus: SchoolStatus,
  RoleCategory: RoleCategory,
  RoleMap: RoleMap,
  RoleLevel: RoleLevel
}