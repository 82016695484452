<template>
  <div class="table-header-column table-header-column-wrapper">
    <i v-if="icon" :class="['icon', icon]"></i>
    <span class="label">{{label}}</span>
    <i v-if="sortKey" :class="[sort == 'desc' ? 'icon-jiangxu' : 'icon-shengxu']" :style="{ cursor: 'pointer', color: (active == sortKey) ? '#66AD8A' : '#606266'}" @click="handleSort"></i>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    active: {
      type: String,
      default: ''
    },
    sortKey: {
      type: String,
      default: "",
    },
    // sort: {
    //   type: String,
    //   default: "",
    // }
  },
  data() {
    return {
      sort: ''
    }
  },

  methods: {

    handleSort() {
      switch (this.sort) {
        case 'asc':
          this.sort = 'desc'
          break
        case 'desc':
          this.sort = ''
          break
        case '':
          this.sort = 'asc'
          break
          default:
            break
      }
      this.$emit('sort-change', (this.sort || '') != '' ? this.sortKey : '', this.sort)
    }
  }
}
</script>

<style lang="scss">
.table-header-column-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #606266;

  .icon,
  .label {
    margin-right: 6px;
  }
}
</style>