<template>
  <div :class="['user-avatar', 'user-avatar-wrapper', circle ? 'circle' : '', border ? 'border' : '']" :style="{width: width + 'px', height: width + 'px'}">
    <el-image class="avatar-img" v-if="(src || '').length > 0" :src="src" alt="" @error="src = null" fit="cover"></el-image>
    <div class="avatar-text" v-else :style="{backgroundColor: background, color: color, fontSize: size + 'px'}">{{ (name || '').substring(0, 2) }}</div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '60'
    },
    background: {
      type: String,
      default: '#66AD8A'
    },
    color: {
      type: String,
      default: '#ffffff'
    },
    size: {
      type: String,
      default: '14'
    },
    circle: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    }

  }
}
</script>

<style lang="scss">
.user-avatar-wrapper {
  width: 36px;
  height: 36px;
  // border-radius: 50%;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
  &.circle {
    border-radius: 50%;
  }
  &.border {
    border: 1px solid #FFFFFF;
  }

  .avatar-img {
    width: 100%;
    height: 100%;
    background-color: black;
  }
  .avatar-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>