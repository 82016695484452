import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


// 引入 elementui
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'

import './assets/css/element-variables.scss'

Vue.use(ElementUI)


// 全局样式
import "./assets/css/common.scss"
import "./assets/css/element-extend/el-button.css"
import "./assets/css/element-extend/el-dialog.scss"
import "./assets/css/iconfont.css"
// import "./assets/css/element-extend.css"
// import "./assets/font/iconfont.css"


import {
  sys_def
} from "./utils/sys_def"
import {
  EZHud
} from "./utils/ezkit/ez-hud"
import { EZDate } from '@/utils/ezkit/ez-date'
import {
  UserUtil
} from "./utils/user"

Vue.config.productionTip = false
Vue.prototype.$sys = sys_def
Vue.prototype.$ezhud = EZHud
Vue.prototype.$ezdate = EZDate
Vue.prototype.$user = UserUtil

// echart 图表
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 剪切板
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// 注册全局组件
import BasePage from '@/components/base-page/index.vue'
Vue.component('BasePage', BasePage)

import BackBar from '@/components/back-bar.vue'
Vue.component('BackBar', BackBar)

import TableHeaderColumn from '@/components/table-header-column/index.vue'
Vue.component('TableHeaderColumn', TableHeaderColumn)

import EzInput from '@/components/ez-input/index.vue'
Vue.component('EzInput', EzInput)

import UserAvatar from '@/components/user-avatar.vue'
Vue.component('UserAvatar', UserAvatar)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");