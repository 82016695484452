// 加密解密
let Base64 = require('js-base64').Base64
import Cookies from 'js-cookie'

const Cookie_Authorization_Key = 'Authorization'
const Cookie_User_Key = 'user'
const Cookie_Role_Key = 'role'

export const UserUtil = {
    save(user) {
        let userJson = JSON.stringify(user)
        let userData = Base64.encode(userJson)
        window.localStorage.setItem(Cookie_User_Key, userData)
    },
    get() {
        let user = null
        try {
            let userData = window.localStorage.getItem(Cookie_User_Key)
            user = JSON.parse(Base64.decode(userData))
        } catch (e) {}

        return user || {}
    },
    remove() {
        window.localStorage.removeItem(Cookie_User_Key)
    },
    isSelf(user) {
        return UserUtil.get().id == user
    },
    hasSuperAdminPermission() {
        return UserUtil.get().isSuperAdmin == 1
    },
    hasAdminPermission(module) {
        let user = UserUtil.get()
        return UserUtil.hasSuperAdminPermission() || ((user.roles || []).filter(item => item.module == module && item.role == 'ADMIN').length > 0)
    }
}