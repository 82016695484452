<template>
  <div class="base-page-wrapper">
    <div v-if="header" class="base-header">
      <slot name="header">
        <div :class="['base-header-wrapper', showBack ? 'has_back' : '']">
          <div v-if="showBack" class="btn-back" @click.stop="$router.back()"><i class="icon-fanhui"></i></div>
          <slot name="header-menu">
            <span></span>
          </slot>
        </div>
      </slot>
    </div>
    <div class="base-content">
      <slot></slot>
      <div v-if="footer" class="base-footer">
        <slot name="footer">I'm footer</slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  props: {
    header: {
      type: Boolean,
      default: true,
    },
    limitModule: {
      type: String,
      default: '',
    },
    footer: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.base-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .base-header {
    width: 100%;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: #ffffff;

    .base-header-wrapper {
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d8d8d8;
      font-size: 16px;

      &.has_back {
        position: relative;
        padding-left: 40px;
        box-sizing: border-box;
      }

      .btn-back {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        left: -10px;
        // top: 50%;
        // transform: translate(0% -50%);
      }

      .purview-wrapper {
        // width: 160px;
        // height: 32px;
        flex-shrink: 0;
        // background: #d8d8d8;
      }
    }
  }

  .base-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .base-footer {
    width: 100%;
    padding: 24px;
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>