<template>
  <el-input ref="ezInputRef" @input="" @input.passive="valueChanged" v-on="$listeners" v-bind="$attrs"></el-input>
</template>

<script>


export default {

  watch: {
    '$attrs.value'(val) {
      console.log('------ ##### ', val)
      this.fixMaxCount()
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    this.fixMaxCount()
  },

  methods: {
    valueChanged(val) {
      console.log(this.$attrs)

      let maxlength = this.$attrs.maxlength
      if (maxlength && maxlength > 0) {
        this.$nextTick(() => {
          let len = this.getValueLength(val)
          if (len > maxlength) {
            let inputNode = {}
            if (this.$attrs.type == 'textarea') {
              inputNode = this.$refs.ezInputRef.$el.getElementsByClassName('el-textarea__inner')[0]
            } else {
              inputNode = this.$refs.ezInputRef.$el.getElementsByClassName('el-input__inner')[0]
            }
            inputNode.value = this.substring(this.$attrs.value, maxlength)
            inputNode.dispatchEvent(new Event('input'))
          }
        })
      }
    },
    fixMaxCount() {
      this.$nextTick(() => {
        let nodes = []
        if (this.$attrs.type == 'textarea') {
          nodes = this.$refs.ezInputRef.$el.getElementsByClassName('el-input__count')
        } else {
          nodes = this.$refs.ezInputRef.$el.getElementsByClassName('el-input__count-inner')
        }
        if ((nodes || []).length > 0) {
          nodes[0].innerText = `${this.getValueLength(this.$attrs.value)}/${this.$attrs.maxlength}`
        }
        this.$forceUpdate()
      })
    },

    getValueLength(val) {

      let length = 0
      let chars = (val || '').split('')
      chars.map((str) => {
        length += this.getCharLen(str)
      })
      return length
    },
    substring(str, length) {
      let len = 0
      let chars = (str || '').split('')
      let newStr = ''
      for (let i = 0; i < chars.length; ++i) {
        let char = chars[i]
        len += this.getCharLen(char)
        if (len > length) {
          return newStr
        }
        newStr += char
      }
      return str
    },
    getCharLen(char) {
      let regx = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/
      // if (val.charCodeAt(i) < 27 || val.charCodeAt(i) > 126)
      return regx.test(char) ? 2 : 1
    }
  },
}
</script>

<style>
</style>