import Vue from "vue";
import VueRouter from "vue-router";

import {
  UserUtil
} from "@/utils/user"

Vue.use(VueRouter);

const routes = [{
    path: '/',
    redirect: 'index'
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("@/pages/login/index.vue")
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/pages/index.vue")
  },
  {
    path: "/share/:code",
    name: "share",
    component: () => import("@/pages/share/index.vue")
  },
  {
    path: "/admin",
    name: "main",
    component: () => import("@/pages/home/index.vue"),
    redirect: '/home',
    children: [{
      path: "/home",
      name: "home",
      component: () => import("@/pages/home/home.vue"),
    }, {
      path: "/schools",
      name: "schools",
      component: () => import("@/pages/schools/index.vue"),
    }, {
      path: "/songs",
      name: "songs",
      component: () => import("@/pages/songs/index.vue"),
    }, {
      path: "/musics",
      name: "musics",
      component: () => import("@/pages/musics/index.vue"),
    }]
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
})

// 挂载路由守卫
router.beforeEach((to, from, next) => {
  let toPath = to.path
  let hasLogged = false
  try {
    let user = UserUtil.get()
    if (user.token && user.token != '') {
      hasLogged = true
    }
  } catch (e) {
    console.log(e)
    hasLogged = false
  }

  console.log(`has logged : ${hasLogged}, to : ${toPath}`)
  if (toPath != '/index' && toPath.indexOf('/share') != 0) {
    if (!hasLogged && toPath != '/sign-in') {
      return next('/sign-in')
    } else if (hasLogged && toPath == '/sign-in') {
      return next("/home")
    }
  }

  return next()
})

export default router;